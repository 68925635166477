import React, { Component } from 'react';
import { useParams } from "react-router";
import PartiesManager from '../../core/parties/PartiesManager';
import { DateTime } from 'luxon';
import Container from '@mui/material/Container';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { cities } from '../../constants/cities'
import { amenities, getIconComponent } from '../../constants/amenities'
import { Place, AccessTimeFilled } from '@mui/icons-material'
import Grid from '@mui/material/Grid';
import ApartmentIcon from '@mui/icons-material/Apartment';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import '../../styles/Login.css';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import { FileUploader } from "react-drag-drop-files";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const fileTypes = ["JPG", "PNG", "GIF"];

class NewParty extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      dateTime : null,
      age : 2,
      myProperties : [],
      files: []
    };
  }

  async componentDidMount() {
    if (this.props.userSession.hostProfile()) {
      console.log(this.props.userSession);
      const myProperties = PartiesManager.getAllMyProperties(this.props.userSession.hostProfile().id);
      console.log(myProperties);
      this.setState({
        ...this.state,
        myProperties : myProperties
      });
    }
  }

  async componentDidUpdate() {
    if (this.props.userSession.account) {
      console.log(this.props.userSession);
      const myProperties = PartiesManager.getAllMyProperties(this.props.userSession.hostProfile().id);
      console.log(myProperties);
      this.setState({
        ...this.state,
        myProperties : myProperties
      });
    }
  }

  setValue(value){
    this.setState({ 
      ...this.state,
      dateTime: value 
    });
  }

  handleDurationChange(newValue) {
    this.setState({
      dateTime: this.state.dateTime,
      age: newValue
    });
  }

  handlePropertyChange(newValue) {

  }

  handleFilesChange(value) {
    this.setState({ 
      ...this.state,
      file: value
    });
  }

  propertyElement(property) {
    return (
      <MenuItem value={property.id}>{property.name}</MenuItem>
    );
  }

  render () {
    const loginElement = (
        <Box sx={{ marginTop: 5, width: "99%", textAlign:"center" }}>
          <Typography variant="body" component="div">
            Sign In or Sign Up to host your party. 
          </Typography>
          <Button href="/signin">Sign In</Button>
          <Button href="/signup">Sign Up</Button>
        </Box>
      );
    
      let mainElement = (
        <Box>
          <Box sx={{ marginTop: 2 }}>
            <TextField sx= {{ width: "50%" }} fullWidth size='Normal' id="standard-basic" label="Party Title" variant="standard" />
          </Box>
          <Box sx={{ marginTop: 3 }}>
            <LocalizationProvider dateAdapter={AdapterLuxon} sx={{ marginTop: 3 }}>
            <DateTimePicker disableMaskedInput disablePast
              renderInput={(props) => <TextField {...props} />}
              label="Party Time"
              value={this.state.dateTime}
              onChange={(newValue) => {
                this.setValue(newValue);
              }}
            />
            </LocalizationProvider>
            <FormControl sx={{ width: "10%", marginLeft: 2 }}>
              <InputLabel id="demo-simple-select-label">Duration</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.age}
                label="Duration"
                onChange={(event) => {
                  this.handleDurationChange(event.target.value);
                }}
              >
                <MenuItem value={1}>1 hour</MenuItem>
                <MenuItem value={2}>2 hours</MenuItem>
                <MenuItem value={3}>3 hours</MenuItem>
                <MenuItem value={4}>4 hours</MenuItem>
                <MenuItem value={5}>5 hours</MenuItem>
                <MenuItem value={6}>6 hours</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Stack direction="row" sx={{ marginTop: 3 }} alignItems="flex-end" justifyContent="flex-start">
            <FormControl sx={{ width: "20%" }}>
              <InputLabel id="demo-simple-select-label">Properties</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.property}
                label="Properties"
                onChange={(event) => {
                  this.handlePropertyChange(event.target.value);
                }}
              >
                {this.state.myProperties.map(el => this.propertyElement(el))}
              </Select>
            </FormControl>
            <Box sx={{ marginLeft: 1 }} component="span">
              <Button startIcon={<AddLocationAltIcon />}>
                Add New Property
              </Button>
            </Box>
          </Stack>
          <Box sx={{ marginTop: 3 }}>
            <FileUploader label="Upload or drop a cover photo for your party right here" handleChange={(file) => {
              this.handleFilesChange(file);
            }} name="file" types={fileTypes} />
          </Box>
          <Box sx={{ marginTop: 3 }}>
            <FormControl fullWidth sx={{ width: "50%"}}>
              <TextField
                id="outlined-multiline-static"
                label="Description"
                multiline
                rows={2}
                placeholder="Describe your party in a few words..."
              />
              </FormControl>
            </Box>
            <Box sx={{ marginTop: 3 }}>
              <FormControl fullWidth sx={{ width: "50%"}}>
                <TextField
                  id="outlined-multiline-static"
                  label="Party Rules"
                  multiline
                  rows={2}
                  placeholder="Set rules for your party..."
                />
              </FormControl>
            </Box>
            <Box sx={{ marginTop: 3 }}>
              <FormControl fullWidth sx={{ width: "50%"}}>
                <TextField
                  id="outlined-multiline-static"
                  label="Party Instruction"
                  multiline
                  rows={2}
                  placeholder="Driving directions, house code etc..."
                />
              </FormControl>
            </Box>
            <Box sx={{ marginTop: 3 }}>
              <FormControl fullWidth sx={{ width: "50%"}}>
                <TextField
                  id="outlined-multiline-static"
                  label="Cancellation Policy"
                  multiline
                  rows={2}
                  placeholder="Specify how party attenders can cancel their spot."
                />
              </FormControl>
            </Box>
            <Box sx={{ marginTop: 3, width: "65%" }}>
              <Typography variant="h6" component="div">
                Amenities
              </Typography>
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                {Array.from(amenities).map((amenity, index) => (
                  <Grid item xs={2} sm={4} md={4} key={index}>
                    <FormControlLabel control={<Checkbox />} label={amenity.label} />
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box sx={{ marginTop: 3, width: "20%" }}>
              <Button sx={{ padding: "8px", width: "100%" }} variant="contained">Create Party</Button>
            </Box>
        </Box>
      );

    return (
      <section className='content details-container'>
        <Container maxWidth="lg" sx={{ paddingTop: "15px" }}>
          <Typography variant="h5" component="div">
            New Party
          </Typography>
          {this.props.userSession.account == null ? loginElement : mainElement}
        </Container>
      </section>
    );
  }
}

export default NewParty;