import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import '../App.css';
import Navbar from './Navbar';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../aws-exports';

class AppError extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="AppError">
        <Router>
          <div>
            <h2>Something went wrong. We are working on it.</h2>
            <h4>{this.props.error.message}</h4>
          </div>
        </Router>
      </div>
    );
  }
}

export default AppError;
