import React, { Component } from 'react';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import '../../styles/Login.css';
import { useNavigate } from "react-router-dom";
import { Auth } from 'aws-amplify';

class LogIn extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="hp-login">
        <div className="container">
          <h1>Welcome to HouzzParty</h1>
          <Authenticator 
            initialState="signIn"
            hideSignUp={true}/>
          <span>
              Don't have an account? <a href="/register">Sign Up</a>
            </span>
        </div>
      </div>
    );
  }
}

export default LogIn;