// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const Gender = {
  "MALE": "MALE",
  "FEMALE": "FEMALE",
  "NOT_SPECIFIED": "NOT_SPECIFIED"
};

const ProfileMode = {
  "GUEST": "GUEST",
  "HOST": "HOST"
};

const PropertyOwnership = {
  "OWNED": "OWNED",
  "RENTED": "RENTED"
};

const MessageSendStatus = {
  "NOT_SENT": "NOT_SENT",
  "SENT": "SENT",
  "FAILED": "FAILED"
};

const CheckinStatus = {
  "PENDING_CHECK_IN": "PENDING_CHECK_IN",
  "CHECKED_IN": "CHECKED_IN",
  "CHECKED_OUT": "CHECKED_OUT"
};

const PaymentStatus = {
  "NOT_PAID": "NOT_PAID",
  "PAID": "PAID",
  "TRANSACTION_FAILED": "TRANSACTION_FAILED",
  "REFUNDED": "REFUNDED"
};

const AttendeeStatus = {
  "INTERESTED": "INTERESTED",
  "GOING": "GOING",
  "NOT_GOING": "NOT_GOING",
  "REQUESTED": "REQUESTED",
  "REJECTED": "REJECTED"
};

const PartyStatus = {
  "PENDING": "PENDING",
  "IN_REVIEW": "IN_REVIEW",
  "ACCEPTED": "ACCEPTED",
  "LIVE": "LIVE",
  "DENIED": "DENIED"
};

const { Accounts, Profiles, Properties, Addresses, Locations, ProfileReviews, Messages, MyParties, Parties, PartyFees } = initSchema(schema);

export {
  Accounts,
  Profiles,
  Properties,
  Addresses,
  Locations,
  ProfileReviews,
  Messages,
  MyParties,
  Parties,
  PartyFees,
  Gender,
  ProfileMode,
  PropertyOwnership,
  MessageSendStatus,
  CheckinStatus,
  PaymentStatus,
  AttendeeStatus,
  PartyStatus
};