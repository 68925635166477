import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Footer from './components/Footer';
import LogIn from './components/auth/LogIn';
import Register from './components/auth/Register';
import SignOut from './components/auth/SignOut';
import NewParty from './components/core/NewParty';
import AppError from './components/AppError';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import { API, graphqlOperation, Hub } from 'aws-amplify';
import { getAccountByEmail } from './graphql/queries';
import { DataStore } from '@aws-amplify/datastore';
import { Accounts,Profiles } from './models';
import SignUpManager from './core/auth/SignUpManager'
import UserSession from './core/auth/UserSession'
import PartyDetails from './components/core/PartyDetails'

Amplify.configure(awsconfig);

class App extends Component {

  constructor(props) {
    super(props);
    this.mounted = false;

    this.state = {
      userSession : new UserSession(null, null)
    };

    this.listener = (data) => {
        switch (data.payload.event) {
            case 'signIn': {
              console.log("sign in");
              this.setupLoginSession();
            }
                break;
            case 'signUp':
              console.log("sign up");
                break;
            case 'signOut':{
              this.setSession(null, null);
              console.log('user signed out');
            }
                break;
            case 'signIn_failure':
                console.log('user sign in failed');
                break;
            case 'tokenRefresh':
                console.log('token refresh succeeded');
                break;
            case 'tokenRefresh_failure':
                console.log('token refresh failed');
                break;
            case 'configured':
                console.log('the Auth module is configured');
        }
    };
    Hub.listen('auth', this.listener);
  }

  setSession(account, loggedInUser) {
    this.setState({
      userSession : new UserSession(account, loggedInUser)
    });
  }

  async setupLoginSession(callback) {
    const _self = this;
    var currentUser = null;
    try {
        currentUser = await Auth.currentAuthenticatedUser();

        if (currentUser) {
          var hpAccount = await API.graphql(graphqlOperation(getAccountByEmail, {email: currentUser.attributes.email}));

          if (!hpAccount.data['getAccountByEmail']) {
            hpAccount = await SignUpManager.addNewUser({
              "firstName": currentUser.attributes['given_name'],
              "lastName": currentUser.attributes['family_name'],
              "email" : currentUser.attributes['email'],
              "phoneNumber" : currentUser.attributes['phone_number'],
              "gender" : currentUser.attributes['gender'],
            }, 'signup');
          }

          _self.setSession(hpAccount.data['getAccountByEmail'], currentUser);
          if (callback) {
            callback();
          }
        }
    } catch(err) {
      _self.setSession(null, null);
      if (callback) {
        callback();
      }
    }
  }

  async componentDidMount() {
    var _self = this;
    this.setupLoginSession(() => {
      _self.mounted = true;
    });
  }

  render() {
    var signoutElement = null;
    if (this.mounted) {
      signoutElement = (
        this.state.userSession.isLoggedIn() ? <SignOut/> : <Navigate to="/login" />
      );
    } else {
      signoutElement = (
        <SignOut/>
      );
    }
    return (
      <div className="App">
        <Router>
          <div className='main'>
            <Navbar userSession={this.state.userSession}/>
            <Routes>
              <Route exact path="/" element={<Home userSession={this.state.userSession}/>} />
              <Route exact path="/index.html" element={<Home userSession={this.state.userSession}/>} />
              <Route exact path="/signin" element={this.state.userSession.isLoggedIn() ? <Navigate to="/"/> : <LogIn userSession={this.state.userSession}/>} />
              <Route exact path="/login" element={this.state.userSession.isLoggedIn() ? <Navigate to="/"/> : <LogIn userSession={this.state.userSession}/>} />
              <Route exact path="/signup" element={<Register/>} />
              <Route exact path="/signout" element={signoutElement} />
              <Route exact path="/party/:permalink" element={<PartyDetails userSession={this.state.userSession}/>} />
              <Route exact path="/newparty" element={<NewParty userSession={this.state.userSession}/>}/>
            </Routes>
          </div>
        </Router>
        <br/>
        <br/>
        <Footer/>
      </div>
    );
  }
}

export default App;
