import {
  AcUnit,
  LocalParking,
  Pool,
  Bed,
  Kitchen,
  Deck,
  Casino,
  SpeakerGroup
} from '@mui/icons-material';

export const amenities = [
  {
    "label" : "Air Conditioner/Heating",
    "icon" : "AcUnit"
  },
  {
    "label" : "Parking",
    "icon" : "LocalParking"
  },
  {
    "label" : "Pool",
    "icon" : "Pool"
  },
  {
    "label" : "Air Beds",
    "icon" : "Bed"
  },
  {
    "label" : "Kitchen",
    "icon" : "Kitchen"
  },
  {
    "label" : "Patio",
    "icon" : "Deck"
  },
  {
    "label" : "Board Games",
    "icon" : "Casino"
  },
  {
    "label" : "Audio System",
    "icon" : "SpeakerGroup"
  }
];

export function getIconComponent(iconName) {
  switch (iconName) {
    case "AcUnit": 
      return (<AcUnit/>);
    case "LocalParking": 
      return (<LocalParking/>);
    case "Pool": 
      return (<Pool/>);
    case "Bed": 
      return (<Bed/>);
    case "Kitchen": 
      return (<Kitchen/>);
    case "Deck": 
      return (<Deck/>);
    case "Casino": 
      return (<Casino/>);
    case "SpeakerGroup": 
      return (<SpeakerGroup/>);
    default:
      return null;
  }
}