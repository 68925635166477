import React, { Component, Fragment } from 'react'
import HomeContent from './HomeContent';

export default class Home extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Fragment>
        <HomeContent />
      </Fragment>
    )
  }
}
