import React, { Component } from 'react';
import FormErrors from "../FormErrors";
import Validate from "../utility/FormValidation";
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { CheckboxField } from '@aws-amplify/ui-react';
import { SelectField } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { DataStore } from '@aws-amplify/datastore';
import { Accounts,Profiles } from '../../models';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { getAccountByEmail } from '../../graphql/queries';

const formFields = {
  signUp: {
    email: {
      order: 1
    },
    given_name: {
      order: 2
    },
    family_name: {
      order: 3
    },
    birthdate: {
      order: 4
    },
    phone_number: {
      order: 5,
      isRequired: false,
    },
    password: {
      order: 6
    },
    confirm_password: {
      order: 7
    }
  }
 };

class LogIn extends Component {   

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="hp-signup-container">
        <div className="container">
          <h1>Sign Up</h1>
          <div className='hp-signup'>
            <br/>
            <Authenticator 
            formFields={formFields} 
            signUpAttributes={[
              'email',
              'given_name',
              'family_name',
              'gender',
              'birthdate',
              'phone_number'
            ]}
            initialState="signUp"
            components={{
              SignUp: {
                FormFields() {
                  const { validationErrors } = useAuthenticator();

                  return (
                    <>
                      {/* Re-use default `Authenticator.SignUp.FormFields` */}
                      <Authenticator.SignUp.FormFields />
                      <SelectField
                        placeholder="Gender"
                        name='gender'
                      >
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </SelectField>
                      {/* Append & require Terms & Conditions field to sign up  */}
                      <CheckboxField
                        errorMessage={validationErrors.acknowledgement}
                        hasError={!!validationErrors.acknowledgement}
                        name="acknowledgement"
                        value="yes"
                        label="I agree with the Terms & Conditions"
                      />
                    </>
                  );
                },
              },
            }}
            services={{
              async validateCustomSignUp(formData) {
                if (!formData.acknowledgement) {
                  return {
                    acknowledgement: 'You must agree to the Terms & Conditions',
                  };
                }
              },
              async handleSignUp(formData) {
                let { username, password, attributes } = formData;
                // custom username
                username = username.toLowerCase();
                attributes.email = attributes.email.toLowerCase();
                return Auth.signUp({
                  username,
                  password,
                  attributes,
                });
              },
              async handleConfirmSignUp(formData) {
                let { username, password, attributes } = formData;
              }
            }}/>
          </div>
        </div>
      </div>
    );
  }
}

export default LogIn;