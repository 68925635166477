/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAccountByEmail = /* GraphQL */ `
  query GetAccountByEmail($email: String!) {
    getAccountByEmail(email: $email) {
      id
      email
      cognitoId
      firstName
      lastName
      nickname
      profilePicURL
      createdOn
      phoneNumber
      gender
      archived
      Profiles {
        items {
          id
          profileName
          profileMode
          ratings
          country
          currency
          archived
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const getAccountByPhoneNumber = /* GraphQL */ `
  query GetAccountByPhoneNumber($phoneNumber: AWSPhone!) {
    getAccountByPhoneNumber(phoneNumber: $phoneNumber) {
      id
      email
      cognitoId
      firstName
      lastName
      nickname
      profilePicURL
      createdOn
      phoneNumber
      gender
      archived
      Profiles {
        items {
          id
          profileName
          profileMode
          ratings
          country
          currency
          archived
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const getProfileReviews = /* GraphQL */ `
  query GetProfileReviews($id: ID!) {
    getProfileReviews(id: $id) {
      id
      text
      rating
      useful
      notUseful
      archived
      Profiles {
        id
        profileName
        profileMode
        ratings
        country
        currency
        archived
        accountID
        Properties {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      ReviewerAccount {
        id
        email
        cognitoId
        firstName
        lastName
        nickname
        profilePicURL
        createdOn
        phoneNumber
        gender
        archived
        Profiles {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      profileReviewsProfilesId
      profileReviewsReviewerAccountId
    }
  }
`;
export const listProfileReviews = /* GraphQL */ `
  query ListProfileReviews(
    $filter: ModelProfileReviewsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfileReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        text
        rating
        useful
        notUseful
        archived
        Profiles {
          id
          profileName
          profileMode
          ratings
          country
          currency
          archived
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        ReviewerAccount {
          id
          email
          cognitoId
          firstName
          lastName
          nickname
          profilePicURL
          createdOn
          phoneNumber
          gender
          archived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileReviewsProfilesId
        profileReviewsReviewerAccountId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProfileReviews = /* GraphQL */ `
  query SyncProfileReviews(
    $filter: ModelProfileReviewsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProfileReviews(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        text
        rating
        useful
        notUseful
        archived
        Profiles {
          id
          profileName
          profileMode
          ratings
          country
          currency
          archived
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        ReviewerAccount {
          id
          email
          cognitoId
          firstName
          lastName
          nickname
          profilePicURL
          createdOn
          phoneNumber
          gender
          archived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        profileReviewsProfilesId
        profileReviewsReviewerAccountId
      }
      nextToken
      startedAt
    }
  }
`;
export const getMessages = /* GraphQL */ `
  query GetMessages($id: ID!) {
    getMessages(id: $id) {
      id
      messageText
      attachment
      sentStatus
      sentTimestamp
      receivedTimestamp
      readTimestamp
      lastReadTimestamp
      archived
      FromProfile {
        id
        profileName
        profileMode
        ratings
        country
        currency
        archived
        accountID
        Properties {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      ToProfile {
        id
        profileName
        profileMode
        ratings
        country
        currency
        archived
        accountID
        Properties {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      messagesFromProfileId
      messagesToProfileId
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        messageText
        attachment
        sentStatus
        sentTimestamp
        receivedTimestamp
        readTimestamp
        lastReadTimestamp
        archived
        FromProfile {
          id
          profileName
          profileMode
          ratings
          country
          currency
          archived
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        ToProfile {
          id
          profileName
          profileMode
          ratings
          country
          currency
          archived
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        messagesFromProfileId
        messagesToProfileId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMessages = /* GraphQL */ `
  query SyncMessages(
    $filter: ModelMessagesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        messageText
        attachment
        sentStatus
        sentTimestamp
        receivedTimestamp
        readTimestamp
        lastReadTimestamp
        archived
        FromProfile {
          id
          profileName
          profileMode
          ratings
          country
          currency
          archived
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        ToProfile {
          id
          profileName
          profileMode
          ratings
          country
          currency
          archived
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        messagesFromProfileId
        messagesToProfileId
      }
      nextToken
      startedAt
    }
  }
`;
export const getMyParties = /* GraphQL */ `
  query GetMyParties($id: ID!) {
    getMyParties(id: $id) {
      id
      attendeeStatus
      paymentStatus
      checkinStatus
      paymentTransactionID
      paymentRetriesCount
      archived
      AttendeeProfiles {
        id
        profileName
        profileMode
        ratings
        country
        currency
        archived
        accountID
        Properties {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      Parties {
        id
        title
        partyDescription
        status
        startTimestampMs
        endTimestampMs
        createdOn
        permalink
        rules
        instructions
        healthSafety
        cancellationPolicy
        archived
        Properties {
          id
          propertyName
          propertyOwnership
          propertyRules
          archived
          profileID
          amenities
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          propertiesAddressId
        }
        HostProfiles {
          id
          profileName
          profileMode
          ratings
          country
          currency
          archived
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        PartyFees {
          id
          baseFee
          tax
          cleaningFee
          otherFee1
          otherFee2
          archived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        partyPics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        partiesPropertiesId
        partiesHostProfilesId
        partiesPartyFeesId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      myPartiesAttendeeProfilesId
      myPartiesPartiesId
    }
  }
`;
export const listMyParties = /* GraphQL */ `
  query ListMyParties(
    $filter: ModelMyPartiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMyParties(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        attendeeStatus
        paymentStatus
        checkinStatus
        paymentTransactionID
        paymentRetriesCount
        archived
        AttendeeProfiles {
          id
          profileName
          profileMode
          ratings
          country
          currency
          archived
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Parties {
          id
          title
          partyDescription
          status
          startTimestampMs
          endTimestampMs
          createdOn
          permalink
          rules
          instructions
          healthSafety
          cancellationPolicy
          archived
          partyPics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          partiesPropertiesId
          partiesHostProfilesId
          partiesPartyFeesId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        myPartiesAttendeeProfilesId
        myPartiesPartiesId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMyParties = /* GraphQL */ `
  query SyncMyParties(
    $filter: ModelMyPartiesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMyParties(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        attendeeStatus
        paymentStatus
        checkinStatus
        paymentTransactionID
        paymentRetriesCount
        archived
        AttendeeProfiles {
          id
          profileName
          profileMode
          ratings
          country
          currency
          archived
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        Parties {
          id
          title
          partyDescription
          status
          startTimestampMs
          endTimestampMs
          createdOn
          permalink
          rules
          instructions
          healthSafety
          cancellationPolicy
          archived
          partyPics
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          partiesPropertiesId
          partiesHostProfilesId
          partiesPartyFeesId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        myPartiesAttendeeProfilesId
        myPartiesPartiesId
      }
      nextToken
      startedAt
    }
  }
`;
export const getPartyFees = /* GraphQL */ `
  query GetPartyFees($id: ID!) {
    getPartyFees(id: $id) {
      id
      baseFee
      tax
      cleaningFee
      otherFee1
      otherFee2
      archived
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPartyFees = /* GraphQL */ `
  query ListPartyFees(
    $filter: ModelPartyFeesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartyFees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        baseFee
        tax
        cleaningFee
        otherFee1
        otherFee2
        archived
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPartyFees = /* GraphQL */ `
  query SyncPartyFees(
    $filter: ModelPartyFeesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPartyFees(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        baseFee
        tax
        cleaningFee
        otherFee1
        otherFee2
        archived
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getParties = /* GraphQL */ `
  query GetParties($id: ID!) {
    getParties(id: $id) {
      id
      title
      partyDescription
      status
      startTimestampMs
      endTimestampMs
      createdOn
      permalink
      rules
      instructions
      healthSafety
      cancellationPolicy
      archived
      Properties {
        id
        propertyName
        propertyOwnership
        propertyRules
        archived
        Address {
          id
          addressLine1
          addressLine2
          addressLine3
          landmark
          city
          state
          country
          archived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          addressesLocationId
        }
        profileID
        amenities
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        propertiesAddressId
      }
      HostProfiles {
        id
        profileName
        profileMode
        ratings
        country
        currency
        archived
        accountID
        Properties {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      PartyFees {
        id
        baseFee
        tax
        cleaningFee
        otherFee1
        otherFee2
        archived
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      partyPics
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      partiesPropertiesId
      partiesHostProfilesId
      partiesPartyFeesId
    }
  }
`;
export const listParties = /* GraphQL */ `
  query ListParties(
    $filter: ModelPartiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listParties(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        partyDescription
        status
        startTimestampMs
        endTimestampMs
        createdOn
        permalink
        rules
        instructions
        healthSafety
        cancellationPolicy
        archived
        Properties {
          id
          propertyName
          propertyOwnership
          propertyRules
          archived
          profileID
          amenities
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          propertiesAddressId
        }
        HostProfiles {
          id
          profileName
          profileMode
          ratings
          country
          currency
          archived
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        PartyFees {
          id
          baseFee
          tax
          cleaningFee
          otherFee1
          otherFee2
          archived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        partyPics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        partiesPropertiesId
        partiesHostProfilesId
        partiesPartyFeesId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncParties = /* GraphQL */ `
  query SyncParties(
    $filter: ModelPartiesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncParties(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        partyDescription
        status
        startTimestampMs
        endTimestampMs
        createdOn
        permalink
        rules
        instructions
        healthSafety
        cancellationPolicy
        archived
        Properties {
          id
          propertyName
          propertyOwnership
          propertyRules
          archived
          profileID
          amenities
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          propertiesAddressId
        }
        HostProfiles {
          id
          profileName
          profileMode
          ratings
          country
          currency
          archived
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        PartyFees {
          id
          baseFee
          tax
          cleaningFee
          otherFee1
          otherFee2
          archived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        partyPics
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        partiesPropertiesId
        partiesHostProfilesId
        partiesPartyFeesId
      }
      nextToken
      startedAt
    }
  }
`;
export const getProperties = /* GraphQL */ `
  query GetProperties($id: ID!) {
    getProperties(id: $id) {
      id
      propertyName
      propertyOwnership
      propertyRules
      archived
      Address {
        id
        addressLine1
        addressLine2
        addressLine3
        landmark
        city
        state
        country
        Location {
          id
          latitude
          longitude
          archived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        archived
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        addressesLocationId
      }
      profileID
      amenities
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      propertiesAddressId
    }
  }
`;
export const listProperties = /* GraphQL */ `
  query ListProperties(
    $filter: ModelPropertiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProperties(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        propertyName
        propertyOwnership
        propertyRules
        archived
        Address {
          id
          addressLine1
          addressLine2
          addressLine3
          landmark
          city
          state
          country
          archived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          addressesLocationId
        }
        profileID
        amenities
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        propertiesAddressId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProperties = /* GraphQL */ `
  query SyncProperties(
    $filter: ModelPropertiesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProperties(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        propertyName
        propertyOwnership
        propertyRules
        archived
        Address {
          id
          addressLine1
          addressLine2
          addressLine3
          landmark
          city
          state
          country
          archived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          addressesLocationId
        }
        profileID
        amenities
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        propertiesAddressId
      }
      nextToken
      startedAt
    }
  }
`;
export const getProfiles = /* GraphQL */ `
  query GetProfiles($id: ID!) {
    getProfiles(id: $id) {
      id
      profileName
      profileMode
      ratings
      country
      currency
      archived
      accountID
      Properties {
        items {
          id
          propertyName
          propertyOwnership
          propertyRules
          archived
          profileID
          amenities
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          propertiesAddressId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $filter: ModelProfilesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        profileName
        profileMode
        ratings
        country
        currency
        archived
        accountID
        Properties {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProfiles = /* GraphQL */ `
  query SyncProfiles(
    $filter: ModelProfilesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        profileName
        profileMode
        ratings
        country
        currency
        archived
        accountID
        Properties {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAccounts = /* GraphQL */ `
  query GetAccounts($email: AWSEmail!) {
    getAccounts(email: $email) {
      id
      email
      cognitoId
      firstName
      lastName
      nickname
      profilePicURL
      createdOn
      phoneNumber
      gender
      archived
      Profiles {
        items {
          id
          profileName
          profileMode
          ratings
          country
          currency
          archived
          accountID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $email: AWSEmail
    $filter: ModelAccountsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAccounts(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        email
        cognitoId
        firstName
        lastName
        nickname
        profilePicURL
        createdOn
        phoneNumber
        gender
        archived
        Profiles {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAccounts = /* GraphQL */ `
  query SyncAccounts(
    $filter: ModelAccountsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        email
        cognitoId
        firstName
        lastName
        nickname
        profilePicURL
        createdOn
        phoneNumber
        gender
        archived
        Profiles {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAddresses = /* GraphQL */ `
  query GetAddresses($id: ID!) {
    getAddresses(id: $id) {
      id
      addressLine1
      addressLine2
      addressLine3
      landmark
      city
      state
      country
      Location {
        id
        latitude
        longitude
        archived
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      archived
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      addressesLocationId
    }
  }
`;
export const listAddresses = /* GraphQL */ `
  query ListAddresses(
    $filter: ModelAddressesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAddresses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        addressLine1
        addressLine2
        addressLine3
        landmark
        city
        state
        country
        Location {
          id
          latitude
          longitude
          archived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        archived
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        addressesLocationId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAddresses = /* GraphQL */ `
  query SyncAddresses(
    $filter: ModelAddressesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAddresses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        addressLine1
        addressLine2
        addressLine3
        landmark
        city
        state
        country
        Location {
          id
          latitude
          longitude
          archived
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        archived
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        addressesLocationId
      }
      nextToken
      startedAt
    }
  }
`;
export const getLocations = /* GraphQL */ `
  query GetLocations($id: ID!) {
    getLocations(id: $id) {
      id
      latitude
      longitude
      archived
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        latitude
        longitude
        archived
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLocations = /* GraphQL */ `
  query SyncLocations(
    $filter: ModelLocationsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        latitude
        longitude
        archived
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
