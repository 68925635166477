class PartyPreview {
    constructor(partyModel, hostAccountModel) {
        this._partyModel = partyModel;
        this._hostAccountModel = hostAccountModel;
    }

    get partyModel() {
        return this._partyModel;
    }

    get hostAccountModel() {
        return this._hostAccountModel;
    }
};

export { PartyPreview };