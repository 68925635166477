import React, { Component } from 'react';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import '../../styles/Login.css';
import { useNavigate } from "react-router-dom";
import { Auth } from 'aws-amplify';
import Button from '@mui/material/Button';

class SignOut extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="hp-login">
        <div className="container">
          <Authenticator 
            initialState="signIn"
            hideSignUp={true}>
              {({ signOut, user }) => (
                <main>
                  <span>Are you sure you want to sign out?</span>
                  <br/>
                  <br/>
                  <Button onClick={signOut} sx={{ padding: "8px" }} variant="contained">Sign Out</Button>
                </main>
              )}
          </Authenticator>
        </div>
      </div>
    );
  }
}

export default SignOut;