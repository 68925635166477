import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

import NearbyParties from './NearbyParties'
import { padding } from '@mui/system';

export default function HomeContent() {
    const cardsData = [
        {
            title: "Attend a party",
            body: "Find and attend interesting house parties in your local area. Connect with people, make new friends and grow your network.",
            link: ""
        },
        {
            title: "Host Parties",
            body: "Organize parties in the comfort of your home. Chose a theme, invite known people, meet new people and just have a good time.",
            link: ""
        },
        {
            title: "Earn Money",
            body: "Earn side cash while having fun.   ",
            link: ""
        }
    ];

    const createCard = (data) => { 
        return (
            <Card sx={{ width: 300, height: 190, paddingTop: 0.25, paddingBottom: 0.25}}>
                <CardContent width="100%">
                    <Typography variant="h5" component="div">
                    {data.title}
                    </Typography>
                    <Typography variant="body2">
                    {data.body}
                    </Typography>
                </CardContent>
                <CardActions width="100%">
                    <Button size="small">Learn More</Button>
                </CardActions>
            </Card>
        );
    };
    return (
        <section className="container">
            <Box sx={{ width: "100%", marginBottom: 3 }}>
                <Stack spacing={4} direction="row" alignItems="flex-start" justifyContent="center">
                    {cardsData.map(data => createCard(data))}
                </Stack>
            </Box>
            <Box sx={{ width: '100%', marginTop: 3 }}>
                <NearbyParties />
            </Box>
        </section>
    );
}
