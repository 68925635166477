import { DataStore } from '@aws-amplify/datastore';
import { Parties, Accounts, Properties, Addresses } from '../../models';
import { PartyPreview } from '../../ViewModels/ViewModels'
import { API, graphqlOperation } from 'aws-amplify'
import { listParties } from '../../graphql/queries'

class PartiesManager {
  constructor(){
  }

  static async getParties(){
    const models = await DataStore.query(Parties);

    var viewModels = [];
    var promises = [];
    models.forEach((model) => {
      promises.push(DataStore.query(Accounts, model.HostProfiles.accountID));
    });

    return new Promise((resolve, reject) => {
      Promise.all(promises)
      .then(dataArray => {
        models.forEach((model, i) => {
          viewModels.push(new PartyPreview(model, dataArray[i]));
        });
        resolve(viewModels);
      })
      .catch(err => {
        console.log("Error while fetching host accounts: " + err);
        resolve(models.map(el => { 
          return new PartyPreview(el, null);
        }));
      });
    });
  }

  static async getPartyDetailsByPermalink(permalink) {
    const result = await DataStore.query(Parties, input => input.permalink("eq", permalink));
    if (result.length == 1) {
      const partyDetails = result[0];
      const hostAccount = await DataStore.query(Accounts, partyDetails.HostProfiles.accountID);
      const address = await DataStore.query(Addresses, partyDetails.Properties.propertiesAddressId);
      return {
        partyModel : partyDetails,
        address : address,
        hostAccount: hostAccount
      };
    } else {
      return null;
    }
  }

  static async getAllMyProperties(profileID) {
    const result = DataStore.query(Properties, input => input.profileID("eq", profileID));
    return result;
  }
}

export default PartiesManager;