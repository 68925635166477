import React, { Component, Fragment } from 'react'
import PartiesManager from '../core/parties/PartiesManager';
import '../styles/Parties.css';
import {Parties} from '../models'
import { WidthProvider, Responsive } from "react-grid-layout";
import { DateTime } from 'luxon';

const ResponsiveReactGridLayout = WidthProvider(Responsive);
export default class NearbyParties extends Component {
  static defaultProps = {
    className: "layout",
    cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
    rowHeight: 100
  };

  constructor(props) {
    super(props);
    this.state = { 
      parties : [],
    };
  }

  componentDidMount() {
    try {
      PartiesManager.getParties()
        .then(viewModels => {
          this.setState({
            parties : viewModels.map((el, i) => {
              return {
                i: i.toString(),
                x: i * 3,
                y: 0,
                w: 3,
                h: 3,
                static: true,
                viewModel: el
              };
            })
          });
        }).catch(err => {
          console.log('Failed to get party models. ' + err);
        });
    } catch(err) {
      console.log('Error: ' + err);
    }
  }

  createElement(renderModel) {

    const hostedByElement = !!renderModel.viewModel.hostAccountModel
    ? (<span>Hosted by {renderModel.viewModel.hostAccountModel.firstName + ' ' + renderModel.viewModel.hostAccountModel.lastName}</span>)
    : (<span></span>);

    const startDateTime = DateTime.fromMillis(renderModel.viewModel.partyModel.startTimestampMs);
    const endDateTime = DateTime.fromMillis(renderModel.viewModel.partyModel.endTimestampMs);

    return (
      
        <div id={renderModel.id} className="item" key={renderModel.i} data-grid={renderModel}>
          <a href={'party/' + renderModel.viewModel.partyModel.permalink}>
          <div className="pic">
            <img src={renderModel.viewModel.partyModel.partyPics[0]} />
          </div>
          <div>
            <div className='titleprice'>
              <div className='party-title'>{renderModel.viewModel.partyModel.title}</div>
              <div className='price'>${parseFloat(renderModel.viewModel.partyModel.PartyFees.baseFee + renderModel.viewModel.partyModel.PartyFees.tax + renderModel.viewModel.partyModel.PartyFees.cleaningFee + renderModel.viewModel.partyModel.PartyFees.otherFee1 + renderModel.viewModel.partyModel.PartyFees.otherFee2)}</div>
            </div>
            <div className='party-datetime'>
              <span className='datetime'>{startDateTime.toFormat('ccc, LLL d')} {startDateTime.toLocaleString(DateTime.TIME_SIMPLE)}-{endDateTime.toLocaleString(DateTime.TIME_SIMPLE)}</span>
            </div>
            <div className='partydesc'>
              <div>{renderModel.viewModel.partyModel.partyDescription.substring(0, 50)}...<a href="/seemore">More</a></div>
            </div>
            <div className='hostinfo'>
              {hostedByElement}
            </div>
          </div>
          </a>
        </div>
      
    );
  }

  render() {
    return (
      <div className='content'>
      <h2>Parties Near You</h2>
      <div className="columns features"> 
        <div className="parties-container">
          <ResponsiveReactGridLayout
            {...this.props}
          >
            {this.state.parties.map(el => this.createElement(el))}
          </ResponsiveReactGridLayout>
        </div>
      </div>
      </div>
    )
  }
}
