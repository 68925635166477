import { ProfileMode } from "../../models";

export default class UserSession {
  constructor(account, loggedInUser) {
    this.account = account;
    this.loggedInUser = loggedInUser;
  }

  logout() {
    this.account = null;
    this.loggedInUser = null;
  }

  isValid() {
    return this.account != null && 
      this.loggedInUser != null && 
      this.account.email === this.loggedInUser.attributes.email;
  }

  isLoggedIn() {
    return this.loggedInUser != null;
  }

  // Conveninent methods 
  nickName() {
    return !!this.account ? this.nickName : null;
  }

  firstLastName() {
    return !!this.account ? this.account.firstName + ' ' + this.account.lastName : 'HP User';
  }

  displayName () {
    return this.nickName() ? this.nickName() : this.firstLastName();
  }

  email() {
    return this.loggedInUser.attributes.email;
  }

  profilePicURL() {
    return !!this.account ? this.account.profilePicURL : null;
  }

  hostProfile() {
    var profiles = [];
    if (this.account) {
      profiles = this.account.Profiles.map((el) => el.profileMode == ProfileMode.HOST);
    }
    return profiles.length == 1 ? profiles[0] : null;
  }

  guestProfile() {
    var profiles = [];
    if (this.account) {
      profiles = this.account.Profiles.map((el) => el.profileMode == ProfileMode.GUEST);
    }
    return profiles.length == 1 ? profiles[0] : null;
  }
}