import React, { Component } from 'react';
import { useParams } from "react-router";
import PartiesManager from '../../core/parties/PartiesManager';
import { DateTime } from 'luxon';
import Container from '@mui/material/Container';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { cities } from '../../constants/cities'
import { Place, AccessTimeFilled } from '@mui/icons-material'
import Grid from '@mui/material/Grid';
import ApartmentIcon from '@mui/icons-material/Apartment';
import Avatar from '@mui/material/Avatar';

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

class PartyDetails extends Component {
  constructor(props) {
    super(props)
    this.permalink = props.params.permalink;
    this.state = {
      partyModel : null
    };
  }

  async componentDidMount() {
    try {
      const partyModel = await PartiesManager.getPartyDetailsByPermalink(this.permalink);
      this.setState(partyModel);
    } catch(err) {
      console.log('Error while fetching party details: ' + JSON.stringify(err));
    }
  }

  createGridItem(amenity) {
    return (
      <Grid item xs={6}>
        <Typography variant="body" component="span">
          <Stack direction="row" alignItems="center">
            - <span style={{ marginLeft: "5px" }}>{amenity}</span>
          </Stack>
        </Typography>
      </Grid>
    );
  }

  render() {
    if (!this.state.partyModel) {
      return (
        <span>Loading...</span>
      );
    }

    const partyModel = this.state.partyModel;
    const hostAccount = this.state.hostAccount;
    const address = this.state.address;
    const hostedByElement = !!hostAccount
    ? (
      <Stack sx={{ paddingRight: "10px" }} direction="row" justifyContent="flex-end" alignItems="center">
        <Typography variant="body2" component="span">
          <span>Hosted by {hostAccount.firstName + ' ' + hostAccount.lastName}</span>
          <br/>
          <a>48 Reviews</a>
        </Typography>
        <Avatar src={hostAccount.profilePicURL} sx={{ width: 32, height: 32, marginLeft: "5px" }}></Avatar>
      </Stack>
    )
    : (<span></span>);

    const hostName = !!hostAccount
    ? (
      <span>{hostAccount.firstName + " " + hostAccount.lastName}</span>
    )
    : (<span></span>);

    const startDateTime = DateTime.fromMillis(partyModel.startTimestampMs);
    const endDateTime = DateTime.fromMillis(partyModel.endTimestampMs);

    const amenities = partyModel.Properties.amenities.map((amenity) => {
      return (
        <Box sx={{ width: '80%' }}>
          <Grid container rowSpacing={2}>
            {this.createGridItem(amenity)}
          </Grid>
        </Box>
      );
    });

    return (
      <section className='content details-container'>
        <Container maxWidth="lg" sx={{ paddingTop: "15px" }}>
          <Stack sx={{ width:"75%" }} direction="row" justifyContent="space-between" alignItems="flex-end">
            <Box sx={{ width: "76%"}}>
              <Typography variant="h5" component="div">
                {partyModel.title}
              </Typography>
              <Typography variant="subtitle1" component="div">
                <Stack direction="row" alignItems="center">
                  {startDateTime.toFormat('ccc, LLL d')} {startDateTime.toLocaleString(DateTime.TIME_SIMPLE)}-{endDateTime.toLocaleString(DateTime.TIME_SIMPLE)}
                </Stack>
              </Typography>
              <Typography variant="subtitle1" component="div">
                <Stack direction="row" alignItems="center">
                  <Place fontSize="small"/><span style={{ marginLeft: "5px" }}>{cities[address.city]}</span>
                </Stack>
              </Typography>
            </Box>
            <Box sx={{ width: "25%"}}>
              {hostedByElement}
            </Box>
          </Stack>
          <Stack sx={{ paddingTop: 3 }} direction="row" spacing="2" justifyContent="space-between" alignItems="flex-start">
            <Box flexDirection="column" sx={{ width: "74%" }}>
              <Box>
                <div className='party-img'>
                  <img src={partyModel.partyPics[0]} />
                </div>
              </Box>
              <Box>
                <Typography variant="h6" component="div">
                  <span className='from-the-host'>From the Host</span> {hostName}
                </Typography>
                <Typography variant="body1" component="div">
                  {partyModel.partyDescription}
                </Typography>
              </Box>
              <Box sx={{ paddingTop: 3 }}>
                <Typography variant="h6" component="div">
                  Party Rules
                </Typography>
                <Typography variant="body1" component="div">
                  {partyModel.rules}
                </Typography>
              </Box>
              <Box sx={{ paddingTop: 3 }}>
                <Typography variant="h6" component="div">
                  Instructions
                </Typography>
                <Typography variant="body1" component="div">
                  {partyModel.instructions}
                </Typography>
              </Box>
              <Box sx={{ paddingTop: 3 }}>
                <Typography variant="h6" component="div">
                  Amenities
                </Typography>
                {amenities}
              </Box>
              <Box sx={{ paddingTop: 3 }}>
                <Typography variant="h6" component="div">
                  Health Safety
                </Typography>
                <Typography variant="body1" component="div">
                  {partyModel.healthSafety}
                </Typography>
              </Box>
            </Box>
            <Box flexDirection="column" sx={{ width: "24%" }}>
              <Card sx={{ width: "100%" }}>
                <CardContent>
                <Stack direction="row" justifyContent="space-between">
                  <Box>
                    <Typography variant="h5" component="span">
                    ${parseFloat(partyModel.PartyFees.baseFee + partyModel.PartyFees.tax + partyModel.PartyFees.cleaningFee + partyModel.PartyFees.otherFee1 + partyModel.PartyFees.otherFee2)}
                  </Typography>
                  <Typography variant="caption" component="span" sx={{ color: "gray" }}>
                    {' '}Per Person
                  </Typography>
                  </Box>
                  <Typography sx={{ color: "green", paddingTop:"3px" }} variant="body2" component="div">
                    8 spots left
                  </Typography>
                </Stack>
                <Box sx={{ paddingTop:3 }}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2" component="span">
                      Base Fee
                    </Typography>
                    <Typography variant="body2" component="span">
                      ${partyModel.PartyFees.baseFee}
                    </Typography>
                  </Stack>
                  <Divider light/>
                  <Stack sx={{ paddingTop: "5px" }} direction="row" justifyContent="space-between">
                    <Typography variant="body2" component="span">
                      Cleaning Fee
                    </Typography>
                    <Typography variant="body2" component="span">
                      ${partyModel.PartyFees.cleaningFee}
                    </Typography>
                  </Stack>
                  <Divider light/>
                  <Stack sx={{ paddingTop: "5px", paddingBottom: "3px" }} direction="row" justifyContent="space-between">
                    <Typography variant="body2" component="span">
                      Tax
                    </Typography>
                    <Typography variant="body2" component="span">
                      ${partyModel.PartyFees.tax}
                    </Typography>
                  </Stack>
                </Box>
                </CardContent>
                <CardActions>
                <Stack sx={{ width: "100%" }} direction="column" alignItems="stretch">
                  <Button sx={{ padding: "8px", width: "100%" }} variant="contained">Request to book</Button>
                  <Typography sx={{ fontStyle: "italic", color: "gray", paddingTop:"3px" }} variant="caption" component="div">
                    *Pay first to request an invite.
                  </Typography>
                </Stack>
                </CardActions>
              </Card>
            </Box>
          </Stack>
        </Container>
      </section>
    );
  }
}

export default (props) => (
  <PartyDetails
      {...props}
      params={useParams()}
  />
);