import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { getAccountByEmail } from '../../graphql/queries';
import { DataStore } from '@aws-amplify/datastore';
import { Accounts, Gender, ProfileMode, Profiles } from '../../models';

class SignUpManager {
  static validateFormData(formData) {
    return !formData.email && 
           !formData.firstName &&
           !formData.phoneNumber;
  }

  static genderFromGenderStr(genderStr) {
    genderStr = genderStr.toLowerCase();
    if (genderStr === 'male') {
      return Gender.MALE;
    } else if(genderStr === 'female') {
      return Gender.FEMALE;
    } else {
      return Gender.NOT_SPECIFIED;
    }
  }

  static profileModeForSource(source) {
    return source === 'host' ? ProfileMode.HOST : ProfileMode.GUEST;
  }

  /**
   * 
   * @param {formData} formData Fields for creating new account
   * @param {source} source Interface which is trying to add a new user
   * @returns Promise<Accounts>
   */
  static async addNewUser(formData, source){
    if (this.validateFormData(formData)) {
      return Promise.reject(new Error('Invalid Input'));
    }
    
    let now = Math.floor((new Date()).getTime());

    var newAccount = null;
    try {
      newAccount = await DataStore.save(
        new Accounts({
          ...formData,
          "profilePicURL" : "/Profile1.png",
          "createdOn": now,
          "gender": this.genderFromGenderStr(formData.gender),
          "archived": false
        })
      );
    } catch(err) {
      return Promise.reject(err);
    }

    var newProfile = null;
    var error = null;
    try {
      newProfile = await DataStore.save(
          new Profiles({
          "profileName": "My new profile",
          "profileMode": this.profileModeForSource(source),
          "ratings": 0.0,
          "country": -1,
          "currency": -1,
          "archived": false,
          "accountID": newAccount.id
        })
      );
    } catch(err) {
      DataStore.delete(newAccount);
      newAccount = null;
      error = err;
    }

    if (newAccount) {
      return Promise.resolve(newAccount);
    } else {
      return Promise.reject(error);
    }
  }
}

export default SignUpManager;
